<template>
  <div style="background: #f7f7f7;">
    <van-tabs v-model="datafrom.eType" color="#1989fa" @click="onClickTabs" sticky>
      <van-tab title="供应资源" name="1"></van-tab>
      <van-tab title="需求资源" name="2"></van-tab>
    </van-tabs>
    <div style="padding-bottom:40px">
      <div v-for="item in list" :key="item.EEId" class="clearfix">
      <div class="serve_list">
        <!-- 左侧 -->
        <span class="list-right">
          <div>负责人:{{item.Lliabler}}</div>
          <div>材料名称:{{item.Title}}</div>
          <div>材料规模:{{item.Model}}</div>
          <div>需要量:{{item.Amount}}</div>
          <div v-if="item.Remark">备注:{{item.Remark}}</div>
          <!-- <div>执行标准代号:GB1355</div> -->
          <!-- <img src="../../assets/hua1.jpg" alt="" /> -->
        </span>
        <!-- 右侧 -->
        <span class="list-left">
          <div class="title">{{item.Name}}</div>
          <div style="text-align: center">
            <a :href="'tel:' + item.Mobile">
              <button class="serveon">
              <van-icon name="phone-o" /> 联 系 企 业
            </button>
            </a>
          </div>
        </span>
      </div>
    </div>
          <div class="hidden">─────── 没有更多啦 ───────</div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o" to="/huayang/classroom">知识讲堂</van-tabbar-item>
      <van-tabbar-item icon="qr" to="/huayang/serve">企业信息</van-tabbar-item>
      <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
      <van-tabbar-item icon="cluster-o" to="/huayang/interaction">区企互动</van-tabbar-item>
      <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo">联系我们</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { WeGetEntElementPage } from "@/api/Hyapi";
export default {
  data() {
    return {
      active: 1,  //底部tab栏
      datafrom:{  //查询参数
        page:1,
        limit:999,
        title:"",
        eType:1,
      },
      list:{}, //数据
    };
  },
  methods:{
    // 获取分页列表
    getEntElementPage(){
      WeGetEntElementPage(this.datafrom).then((res)=>{
        this.list = res.data.data;
      })
    },
    // tab栏点击
    onClickTabs(e){
      this.datafrom.eType = e;
      this.getEntElementPage();
    }
  },
  mounted(){
    this.getEntElementPage();
  },
};
</script>
<style scoped>
.serve_list {
  position: relative;
  width: 90%;
  margin: 10px 5%;
  border-radius: 10px;
  background: #fff;
}
.serve_list div {
  /* display: inline; */
}
.serve_list span {
  display: inline-block;
}
.serve_list .list-left {
  position: relative;
  right: 0px;
  top: -5px;
  width: 50%;
  padding-bottom: 14px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.serve_list .list-left .title {
  font-size: 14px;
  margin: 25px 5px;
  text-align: center;
}
.serve_list .list-right {
  position: relative;;
  left: 0px;
  top: 0px;
  padding-bottom: 15px;
  font-size: 14px;
  width: 50%;
  color: #666666;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.serve_list .list-right div {
  margin: 8px 10px;
}
.serve_list .serveon {
  border: none;
  background: #1989fa;
  color: #fff;
  padding: 5px 13px;
  border-radius: 20px;
  margin: 10px 0px;
  width: 70%;
  font-size: 14px;
}
.hidden {
  margin: 15px 0px;
  text-align: center;
  color: #666;
  font-size: 12px;
}
</style>